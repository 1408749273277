import React from 'react';

function NotFound() {
    return (
        <div className="404">
            no
        </div>
    )
}


export default NotFound;

